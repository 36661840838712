'use client';

import useStore from '@/store/useStore';
import { useUserRole, useUserRoleActions, UserRole } from '@/store/useUserRole';

import LoginForm from '@/components/LoginCustomerForm';
import LoginHairdresserForm from '@/components/LoginHairdresserForm';

import { useAuthStore } from '@/store/useAuthStore';
import UserRoutesNav from '@/components/UserRoutesNav';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import Link from 'next/link';

export default function Login() {
  const router = useRouter();
  const authenticated = useAuthStore((state) => state.authenticated);

  const userRole = useStore(useUserRole, (userRole) => userRole);
  const translation = useTranslations('UserDrawer');
  const { setUserRole } = useUserRoleActions();
  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <div className="mx-auto mt-8 max-w-lg rounded-lg bg-white p-8">
        <div className="px-4 ">
          <div className="relative flex h-16 rounded-full bg-gray-50 p-2 font-semibold">
            <div className="absolute left-0 z-10 flex h-12 w-full px-2">
              <button
                className={`flex-1 transition-colors ${
                  userRole === UserRole.Customer ? 'text-white' : ''
                }`}
                onClick={() => setUserRole(UserRole.Customer)}
              >
                {translation('customerTitle')}
              </button>
              <Link
                href="https://member.gentsgo.com"
                className={`flex flex-1 items-center justify-center transition-colors ${
                  userRole === UserRole.Hairdresser ? 'text-white' : ''
                }`}
              >
                {translation('hairdresserTitle')}
              </Link>
            </div>
            <div
              className={`h-12 w-1/2 rounded-full bg-blue-600 transition ${
                userRole === UserRole.Customer
                  ? 'translate-x-0'
                  : 'translate-x-full'
              }`}
            />
          </div>
        </div>
        <div className="mx-auto mt-4 w-full  px-4">
          {userRole === UserRole.Customer ? (
            <>
              {!authenticated ? (
                <div className="p-2">
                  <h2 className=" text-lg font-semibold">
                    {translation('customerLoginTitle')}
                  </h2>
                  <LoginForm />
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            userRole === UserRole.Hairdresser && (
              <>
                {!authenticated ? (
                  <div className="p-2">
                    <h2 className=" text-lg font-semibold">
                      {translation('hairdresserLoginTitle')}
                    </h2>
                    <LoginHairdresserForm />
                  </div>
                ) : (
                  <></>
                )}
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
}
